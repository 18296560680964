<!--
  연계상담 선택
-->
<template>
  <el-select :value="value" @input="onChange" filterable placeholder="연계상담 선택" clearable>
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
  data() {
    return {
      options: [
        { label: '직접선택', value: '직접선택' },
        { label: '연계요청', value: '연계요청' },
        { label: '이전센터', value: '이전센터' },
      ],
    };
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>
