<!--
  기간 선택 컴포넌트
  - Props
    {sdate: String, edate: String}
  - emit iput
    {sdate: String, edate: String}

  - 시작일은 종료일 이전
-->
<template>
  <div class="date-range">
    <el-date-picker :value="value.sdate" @input="onBeginChange" type="date" placeholder="시작일">
    </el-date-picker>
    ~
    <el-date-picker
      :value="value.edate"
      @input="onEndChange"
      type="date"
      placeholder="종료일"
      :picker-options="endPickerOptions"
    >
    </el-date-picker>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  props: {
    value: {
      default: () => ({
        sdate: '',
        edate: '',
      }),
    },
  },
  data() {
    return {
      endPickerOptions: {
        disabledDate: time => {
          return moment(time).isBefore(moment(this.value.sdate));
        },
      },
    };
  },
  methods: {
    onBeginChange(val) {
      const formattedDate = val ? moment(val).format('YYYY-MM-DD') : '';
      this.$emit('input', { sdate: formattedDate, edate: this.value.edate });
    },
    onEndChange(val) {
      const formattedDate = val ? moment(val).format('YYYY-MM-DD') : '';
      this.$emit('input', { sdate: this.value.sdate, edate: formattedDate });
    },
  },
};
</script>

<style>
.date-range .el-date-editor.el-input {
  width: 9rem;
}
</style>
