<!--
  조회 버튼

  이벤트
  - reset : 조회 조건 지우기 버튼 클릭
-->

<template>
  <el-form-item>
    <el-button-group>
      <el-button
        :loading="showLoading && submitLoading"
        :disabled="resetLoading"
        native-type="submit"
        @click.prevent="onSearchClick"
        icon="el-icon-search"
        type="primary"
        >조회</el-button
      >
      <el-tooltip content="조회 조건 지우기" placement="top">
        <el-button
          :loading="showLoading && resetLoading"
          :disabled="submitLoading"
          icon="el-icon-close"
          type="info"
          @click="onResetClick"
        />
      </el-tooltip>
    </el-button-group>
  </el-form-item>
</template>

<script>
export default {
  name: 'SearchButton',
  props: {
    showLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      submitLoading: false,
      resetLoading: false,
    };
  },
  mounted() {
    console.log('showLoading', this.showLoading);
  },
  methods: {
    onSearchClick() {
      this.$emit('submit');
      this.submitLoading = true;
    },
    onResetClick() {
      this.$emit('reset');
      this.resetLoading = true;
    },
  },
};
</script>

<style></style>
