<!--
  목록페이지 검색조건 layout
-->
<template>
  <div class="search-box frm_sch"><slot>검색 조건</slot></div>
</template>

<script>
export default {
  name: 'SearchBox',
};
</script>

<style lang="scss">
.search-box {
  margin: 18px 0;
  padding: 11px 10px 0;
  background-color: #f2f2f2;
}

.search-box {
  label {
    font-weight: normal;
  }
  .el-form-item {
    margin-bottom: 11px;
  }
}
</style>
