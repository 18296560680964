<!--
  상담유형 선택
-->
<template>
  <el-select :value="value" @input="onChange" placeholder="상담유형 선택" clearable>
    <el-option v-for="item in options" :key="item.key" :label="item.label" :value="item.value" />
  </el-select>
</template>

<script>
import request from '@/utils/request';

export default {
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
    async fetchData() {
      const data = await request.get('/api/interview-select.php');
      //console.log(data);
      this.options = data;
    },
  },
};
</script>
