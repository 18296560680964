<template>
  <el-pagination
    background
    layout="total, prev, pager, next"
    :total="total"
    :current-page="curPage"
    @current-change="gotoPage"
/></template>

<script>
export default {
  data() {
    // eslint-disable-next-line no-undef
    return { total: PAGE.total || 0, curPage: PAGE.current || 1 };
  },
  methods: {
    gotoPage(page) {
      this.curPage = page;
    },
  },
};
</script>

<style lang="scss">
// 구 UI에 맞춰 폰트 크기 조정
.el-pagination {
  text-align: center;
  margin: 10px 0;
  span:not([class*='suffix']).el-pagination__total {
    font-size: 14px;
  }
  .el-pager li {
    font-size: 14px;
  }
  .btn-prev .el-icon {
    font-size: 14px !important;
  }
  .btn-next .el-icon {
    font-size: 14px !important;
  }
}
</style>
