<!--
  상담: 처리상태
-->
<template>
  <el-select :value="value" @input="onInput" placeholder="집계타입 선택" style="width: 10rem">
    <el-option label="월별" value="1" />
    <el-option label="종결" value="2" />
  </el-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '1',
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style></style>
