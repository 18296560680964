<!--
  전문분야 선택
-->

<template>
  <el-select :value="value" @input="onInput" placeholder="전문분야 선택" clearable>
    <el-option label="가족상담" value="가족상담" />
    <el-option label="부부상담" value="부부상담" />
    <el-option label="아동상담" value="아동상담" />
    <el-option label="청소년상담" value="청소년상담" />
    <el-option label="노인상담" value="노인상담" />
    <el-option label="놀이치료" value="놀이치료" />
    <el-option label="미술치료" value="미술치료" />
    <el-option label="언어치료" value="언어치료" />
    <el-option label="학습/진로상담" value="학습/진로상담" />
    <el-option label="진로상담" value="진로상담" />
    <el-option label="직장인상담/코칭" value="직장인상담/코칭" />
    <el-option label="위기상담(우울/자살/중독 등)" value="위기상담(우울/자살/중독 등)" />
    <el-option label="종합심리평가" value="종합심리평가" />
    <el-option label="기타" value="기타" />
  </el-select>
</template>

<script>
export default {
  name: 'SpecializationSelect',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style></style>
