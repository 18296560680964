<!--
  고객사 선택
-->
<template>
  <el-select :value="value" @input="onChange" filterable placeholder="고객사 선택" clearable>
    <el-option
      v-for="item in filteredOptions"
      :key="item.key"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import request from '@/utils/request';

export default {
  props: {
    value: {
      type: String,
    },
    labelAsValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    filteredOptions() {
      return this.options.map(item => ({
        key: item.value,
        value: this.labelAsValue ? item.label : item.value,
        label: item.label,
      }));
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
    async fetchData() {
      const data = await request.get('/api/company-select.php');
      this.options = data;
    },
  },
};
</script>
