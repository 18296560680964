<!--
  키워드 검색어 입력 필드
-->

<template>
  <el-form-item>
    <el-input
      :value="value.keyword"
      @input="onInput"
      name="keyword"
      :placeholder="placeholder"
      class="input-with-select"
    >
      <el-select v-model="value.searchField" name="searchField" slot="prepend" :style="selectWidth">
        <!--        <el-option label="전체" value></el-option>-->
        <el-option
          v-for="field in searchOptions"
          :key="field.value"
          :label="field.label"
          :value="field.value"
        />
      </el-select>
    </el-input>
  </el-form-item>
</template>

<script>
export default {
  name: 'SearchKeywordInput',
  props: {
    // 검색필드 목록
    searchOptions: {
      default: [],
    },
    // 검색어, 검색필드
    value: {
      default: {
        keyword: '',
        searchField: {
          type: String,
          default: undefined,
        },
      },
    },
    // 검색필드 select 폭
    width: {
      type: String,
      default: '100px',
    },
  },
  computed: {
    selectWidth() {
      return `width: ${this.width}`;
    },
    placeholder() {
      return '검색어 입력';
      // return this.searchOptions.map(opt => opt.label).join('/') + ' 항목 검색'
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', {
        keyword: val,
        searchField: this.value.searchField,
      });
    },
  },
};
</script>

<style>
input::placeholder {
  font-size: 14px !important;
}
</style>
