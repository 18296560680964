/*
 * 전체 페이지 공통.
 * chunks-common.js 로 빌드된다.
 */

import '@babel/polyfill';
import Vue from 'vue';
import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/ko'; // lang i18n

Vue.use(Element, {
  size: 'large', // set element-ui default size
  locale,
});
Vue.config.productionTip = false;

// element-ui 사용
import '../styles/element-variables.scss';
import '@/styles/index.scss'; // global css

// 컴포넌트 등록
// import './icons'; // icon

export default {};
