<!--
  상담: 처리상태
-->
<template>
  <el-select
    :value="value"
    @input="onInput"
    placeholder="처리상태 선택"
    style="width: 10rem"
    clearable
  >
    <el-option label="전문가배정완료" value="전문가배정완료" />
    <el-option label="전문가배정전" value="전문가배정전" />
    <el-option label="배정지연" value="배정지연" />
    <el-option label="상담진행" value="상담진행" />
    <el-option label="상담종료" value="상담종료" />
    <el-option label="상담취소" value="상담취소" />
    <el-option label="상담지연" value="상담지연" />
    <el-option label="진행지연" value="진행지연" />
    <el-option label="만족도작성완료" value="만족도작성완료" />
  </el-select>
</template>

<script>
export default {
  name: 'councelStatus',
  props: {
    value: {
      type: String,
      default: undefined,
    },
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style></style>
