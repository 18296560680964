<!--
  프로젝트 선택
-->
<template>
  <el-select :value="value" @input="onChange" filterable placeholder="프로젝트 선택" clearable>
    <el-option
      v-for="item in filteredOptions"
      :key="item.key"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import request from '@/utils/request';

export default {
  props: {
    value: {
      type: String,
    },
    company: {
      type: String,
    },
    labelAsValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    /**
     * company 코드가 있으면 기업코드로 필터링한다
     */
    filteredOptions() {
      const filtered = this.company
        ? this.options.filter(prj => prj.company === this.company)
        : this.options;
      // key,valeu,label로 재구성
      // {value, label} --> {key, value, label}
      // value는 labelAsValue에 따라서 value를 그대로 사용하거나 label로 대체하거나.
      return filtered.map(item => ({
        key: item.value,
        value: this.labelAsValue ? item.label : item.value,
        label: item.label,
      }));
    },
  },
  watch: {
    /**
     * 기업코드 변경되면 프로젝트 선택을 제거한다.
     */
    company(newCompany, oldCompany) {
      if (newCompany && newCompany !== oldCompany) {
        this.$emit('input', undefined);
      }
    },
  },
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
    async fetchData() {
      const data = await request.get('/api/project-select.php');
      this.options = data;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
